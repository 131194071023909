//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  name: "MessageList",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    imgChange,
  },
};
